.cmn--btn {
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	color: var(--white-clr);
	background: var(--btn-clr);
	padding: 4px 20px;
	line-height: 25px;
	border-radius: 18px;
	border: 1px solid var(--btn-clr);
	transition: all ease 0.3s;
	&:hover {
		color: var(--white-clr);
		opacity: 0.8;
	}
}
.cmn--btn2 {
	font-size: 14px;
	text-transform: capitalize;
	color: var(--base-clr);
	background: rgba(var(--btn-rgb), 0.3);
	padding: 5px 16px;
	line-height: 26px;
	border-radius: 18px;
	border-radius: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	i {
		font-size: 18px;
		margin-left: 5px;
		font-weight: 700;
	}
	&:hover {
		color: var(--white-clr);
		filter: brightness(0.8);
		background: var(--btn-clr);
	}
}
